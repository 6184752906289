<template>
  <v-container class="bg-color-theme">
    <v-card>
      <v-card-text class="py-8">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="submit" class="detial-form">
            <v-row no-gutters class="text-center mb-4">
              <v-col cols="12" class="py-0">
                <v-avatar size="100">
                  <v-img
                    :src="imageSrc"
                    :max-width="imageDimensions.width"
                    :max-height="imageDimensions.height"
                  >
                  </v-img>
                </v-avatar>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="field in fields"
                :key="field.name"
                cols="12"
                :md="field.col ? field.col : '12'"
                class="py-2"
              >
                <template v-if="field.type == 'text'">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="field.rules"
                    :name="field.name"
                  >
                    <v-text-field
                      v-model="field.value"
                      :error-messages="errors"
                      :hide-details="errors.length <= 0"
                      :label="$t('profile_input_label_' + field.name)"
                      autocomplete="chrome-off"
                      class="font-15px"
                    >
                    </v-text-field>
                  </validation-provider>
                </template>
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-radio-group class="font-14px" dense row v-model="form.radio">
                  <div>
                    <p class="font-14px text-777">アクセス権限</p>
                    <v-radio value="M" color="#602BD0">
                      <template v-slot:label>
                        <span class="font-14px">マスター</span>
                      </template>
                    </v-radio>
                  </div>
                  <div>
                    <p class="font-10px line-height-12 text-85">
                      パートユーザーは、日報、現金出納、<br />買掛の作成のみ可能です。
                    </p>
                    <v-radio value="P" color="#602BD0">
                      <template v-slot:label>
                        <span class="font-14px">パート</span>
                      </template>
                    </v-radio>
                  </div>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-btn
                  v-if="getShopAuthUser[0].user_role == 'M'"
                  class="font-weight-bold"
                  block
                  :disabled="invalid"
                  color="primary"
                  type="submit"
                >
                  更新する
                </v-btn>
                <v-btn
                  v-if="getShopAuthUser[0].user_role == 'M'"
                  elevation="3"
                  outlined
                  :disabled="invalid"
                  block
                  color="white"
                  type="button"
                  class="primary--text btn-blue-outline mt-3 font-weight-bold"
                  @click="deleteItem()"
                  >店舗ユーザーから除外する
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UserDetails",
  methods: {
    update() {
      this.$store.dispatch("SHOW_ALERT", {
        text: "編集内容を保存しました。",
        successStatus: "info"
      });
    }
  },
  data() {
    return {
      files: [],
      form: { radio: "M" },
      fields: [
        {
          name: "surname",
          type: "text",
          value: "",
          rules: "required:姓",
          col: 6
        },
        {
          name: "name",
          type: "text",
          value: "",
          rules: "required:名",
          col: 6
        },
        {
          name: "furigana_surname",
          type: "text",
          value: "",
          rules: "required:メイ",
          col: 6
        },
        {
          name: "furigana_name",
          type: "text",
          value: "",
          rules: "required:セイ",
          col: 6
        },
        {
          name: "position",
          type: "text",
          value: "",
          rules: "required:役職"
        },
        {
          name: "telephone_number",
          type: "text",
          value: "",
          rules: "required:電話番号"
        },
        {
          name: "email",
          type: "text",
          value: "",
          rules: "required:メールアドレス|email"
        }
      ]
    };
  },
  props: ["id"],
  computed: {
    ...mapGetters(["user", "getShop", "getShopAuthUser", "getShopUserById"]),
    imageSrc() {
      if (!this.getShopUserById[0]?.["user"]?.avatar) {
        return require("@/assets/images/profile-avatar.svg");
      }

      return this.getShopUserById[0]?.["user"]?.avatar;
    },
    imageDimensions() {
      return { width: "100%", height: "100%" };
    }
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      let authUserData = {
        shop_id: this.$route.query.shop, //Shop Id
        user_id: this.user.id
      };

      this.$store.dispatch("SHOP_USER_AUTH", authUserData).finally(() => {
        this.pageLoading = false;
      });

      let userData = {
        shop_id: this.$route.query.shop, //Shop Id
        user_id: this.id
      };

      this.$store.dispatch("SHOP_USER_BY_USERID", userData).finally(() => {
        this.pageLoading = false;
        this.patchEditFormValues();
      });

      let param = {
        id: this.$route.query.shop //Shop Id
      };
      this.$store.dispatch("SHOP_GET", param).finally(() => {
        this.pageLoading = false;
      });
    },
    patchEditFormValues() {
      this.fields.forEach(field => {
        if (this.getShopUserById.length > 0) {
          field.value = this.getShopUserById[0]["user"]?.[field.name];
        } else {
          field.value = "";
        }
      });

      if (this.getShopUserById.length > 0) {
        this.form.radio = this.getShopUserById[0]?.["user_role"];
      }
    },
    submit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }

        let params = {};
        let shop_id = this.$route.query.shop;
        let user_id = this.id;
        this.fields.forEach(field => {
          if (field) {
            if (field.value) {
              params[field.name] = field.value;
            }
          }
        });

        params["user_role"] = this.form.radio;

        this.$store
          .dispatch("SHOP_USER_UPDATE", { params, shop_id, user_id })
          .then(
            response => {
              if (response.status === 200) {
                this.$router.push({
                  name: "StoreDetails",
                  params: { id: shop_id }
                });
                this.$store.commit("showAlert", {
                  text: "編集内容を保存しました。",
                  successStatus: "info"
                });
              }
            },
            error => {
              this.$store.commit("showAlert", {
                text: "この店舗は少なくとも一人のマスターユーザーが必要です。",
                successStatus: "info"
              });
              throw error;
            }
          );
      });
    },
    deleteItem() {
      this.$root.confirm
        .open(
          `このユーザーを店舗管理メンバーから\n除外しますか？\n` +
            `※招待することで\nメンバーへの再追加が可能ですz`,
          {}
        )
        .then(res => {
          if (res) {
            let params = {};
            params = {
              shop_id: this.$route.query.shop, //Shop Id
              user_id: this.id
            };
            this.$store.dispatch("SHOP_USER_DELETE", params).then(
              response => {
                if (response.status === 200) {
                  this.$router.push({
                    name: "StoreDetails",
                    params: { id: params.shop_id }
                  });
                  this.$store.commit("showAlert", {
                    text: "ユーザーを除外しました。",
                    successStatus: "info"
                  });
                }
              },
              error => {
                this.$store.commit("showAlert", {
                  text:
                    "この店舗は少なくとも一人のマスターユーザーが必要です。",
                  successStatus: "info"
                });
                throw error;
              }
            );
          }
        });
    }
  }
};
</script>
<style lang="scss" scope src="./style.scss"></style>
